import React from 'react';

import Layout from '../components/layouts/en';
import PrivacyPolicy from '../components/privacy-policy';


const Privacy = () => <Layout>
  <PrivacyPolicy />
</Layout>

export default Privacy